import React, { useEffect } from 'react'
import BlockContent from '../components/block-content'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Figure from '../components/figure'
import Breadcrumbs from '../components/breadcrumbs'

const PageTemplate = (props) => {
  const { data, errors} = props
  const page = data && data.page
  const showTopSection = !!page.lead && !!page.image && !!page.image.asset

  useEffect(() => {
    if (page.slug.current === 'informasjonskapsler') {
      const script = document.createElement('script')
      script.src = 'https://consent.cookiebot.com/eee1e1a4-cfb4-4bda-b8c8-bbe3a6d4c425/cd.js'
      script.async = true
      document.getElementById('cookie-declaration').appendChild(script)
    }
  }, [])

  return (
    <>
      <SEO title={page.title}/>
      <Breadcrumbs crumbs={[ page ]} />
      {showTopSection && (
        <header className='block lg:grid grid-cols-2 bg-primary-background-beige'>
          {page.image && (
            <Figure
              node={page.image}
              aspectRatio={1}
              className='w-full h-full'
            />
          )}
          <div className='w-full px-5 pt-12 pb-16 lg:pt-16 lg:px-12 xl:px-20'>
            <BlockContent className='h-full flex items-center page lead' blocks={page.lead} />
          </div>
        </header>
      )}
      <div className={`mx-5 md:mx-10 lg:mx-20 py-12 ${!showTopSection ? 'header-offset' : ''}`}>
        <div className='max-w-screen-md mx-auto'>
          {!showTopSection && page.lead && (
            <BlockContent className='editorial lead' blocks={page.lead} />
          )}

          {page.body && (
            <BlockContent className='editorial' blocks={page.body} />
          )}

          {page.slug.current === 'informasjonskapsler' && (
            <div id='cookie-declaration'></div>
          )}
        </div>
      </div>
    </>
  )
}

export default PageTemplate

export const query = graphql`
  query PageQuery($id: String!) {
    page: sanityPage(id: {eq: $id}) {
      id
      title
      slug {
        _key
        _type
        current
      }
      image {
        asset {
          id
          fluid(maxWidth: 1280) {
            ...GatsbySanityImageFluid
          }
        }
        alt
      },
      lead: _rawLead(resolveReferences:{maxDepth:100})
      body: _rawBody(resolveReferences:{maxDepth:100})
    }
  }
`